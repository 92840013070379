import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'header-admin',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  search = '';
  pages = [];

  currentStyle: string = 'dark-content';

  constructor(
    public auth: AuthService,
    public global: GlobalService,
    public router: Router
  ) {
    this.buildPages();
  }

  ngOnInit() { }

  goTo(page: any) {
    if (page.route) {
      this.router.navigate([page.route]).then(() => {
        this.global.menuOpen = false;
      });
    } else {
      page.openGroup = !page.openGroup;
    }
  }

  logout() {
    this.auth.logout();
    this.global.menuOpen = false;
    this.router.navigate(['/auth/login']);
  }

  onSearchChange(ev) {
    if (!ev) {
      this.buildPages();
    }
  }

  buildPages() {
    this.pages = [
      { name: 'Home', icon: 'fas fa-home', route: '/' },
      {
        name: 'Atuações',
        icon: 'fas fa-globe-americas',
        route: '/atuacoes',
      },
      { name: 'Banners', icon: 'fas fa-image', route: '/banners' },
      {
        name: 'Benefícios',
        icon: 'fas fa-hand-holding-medical',
        route: '/beneficios',
      },
      { name: 'Blogs', icon: 'fas fa-newspaper', route: '/blogs' },
      { name: 'Cases', icon: 'fas fa-suitcase', route: '/cases' },
      { name: 'Clientes', icon: 'fas fa-user-tie', route: '/clientes' },
      {
        name: 'Depoimentos',
        icon: 'fas fa-comments',
        route: '/depoimentos',
      },
      {
        name: 'Diferenciais',
        icon: 'fas fa-balance-scale-right',
        route: '/diferenciais',
      },
      { name: 'Indicadores', icon: 'fas fa-plus', route: '/indicadores' },
      {
        name: 'Parceiros',
        icon: 'fas fa-user-friends',
        route: '/parceiros',
      },
      { name: 'Sobre', icon: 'fas fa-info', route: '/sobre' },
      { name: 'Soluções', icon: 'fas fa-list', route: '/solucoes' },
      { name: 'Vídeos', icon: 'fas fa-video', route: '/videos' },
      {
        name: 'Usuários',
        icon: 'fas fa-user-shield',
        route: '/usuarios',
      },
      {
        name: 'Cadastro Geral',
        icon: 'fas fa-book',
        route: '/cadastro-geral',
      },
      {
        name: 'Contato Geral',
        icon: 'fas fa-phone-alt',
        route: '/contato-geral',
      },
      {
        name: 'Scripts',
        icon: 'fas fa-code',
        route: '/scripts',
      },
      {
        name: 'Configurações',
        icon: 'fas fa-cogs',
        group: [
          {
            name: 'Geral',
            icon: 'fas fa-cog',
            route: '/configuracao-geral',
          },
          {
            name: 'E-mail',
            icon: ' fas fa-paper-plane',
            route: '/configuracao-email',
          },
        ],
        openGroup: false,
      },
    ];
  }

  getContrast = (hexcolor) => {
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }

    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor
        .split('')
        .map(function (hex) {
          return hex + hex;
        })
        .join('');
    }

    // Convert to RGB value
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Check contrast
    return yiq >= 128 ? 'black' : 'white';
  };
}
