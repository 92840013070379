import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum statusBarStyles {
  LIGHT = "light-content",
  DARK = "dark-content",
  HALF_LIGHT = "half-light-content",
  HALF_DARK = 'half-dark-content'
}

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  apiUrl: string = 'https://api.telmaster.com.br';
  imageUrl: string = 'https://api.telmaster.com.br/'
  menuOpen: boolean = false;

  statusBar = new Subject<statusBarStyles>();
  hideFooterContact = new Subject<boolean>();

  constructor() { }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

}
