import { GlobalService } from './global.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Usuario } from '../models/usuario.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  redirectUrl = '';
  isRecuperarSenha: boolean = false;
  token: string;
  user: any;

  constructor(public http: HttpClient, public global: GlobalService) {}

  login(user) {
    return this.http.post<any>(
      this.global.apiUrl + '/usuario/autenticar',
      user
    );
  }

  getAuthorizationHeader() {
    return new HttpHeaders().append('Authorization', 'Basic ' + this.token);
  }

  getUserStorage() {
    return JSON.parse(localStorage.getItem('user_telmaster'));
  }

  setToken(token) {
    if (!token) {
      return;
    }

    this.token = token;
    localStorage.setItem('token_telmaster', this.token);
  }

  setUser(user) {
    if (!user) {
      return;
    }

    localStorage.setItem('user_telmaster', JSON.stringify(user));
    this.user = this.getUserStorage();
  }

  getUser(user) {
    return new Promise<Usuario>((resolve, reject) => {
      this.http
        .get<Usuario>(
          this.global.apiUrl + '/usuario/buscar?email=' + user.email
        )
        .subscribe({
          next: (res) => resolve(res),
          error: (e) => reject(),
        });
    });
  }

  logout() {
    this.token = undefined;
    this.user = null;
    localStorage.clear();
  }

  isLoggedIn() {
    if (!this.token) {
      if (localStorage.getItem('token_telmaster')) {
        this.setToken(localStorage.getItem('token_telmaster'));
        this.user = this.getUserStorage();
      } else {
        return false;
      }
    }

    return true;
  }

  forgotPassword(user) {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.global.apiUrl + '/usuario/esqueceuSenha?email=' + user.email)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (e) => {
            reject(e);
          }
        );
    });
  }
}
