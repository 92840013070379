import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// imports
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';
import { MaterialModule } from './material/material.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NgxSummernoteModule } from 'ngx-summernote';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
// services
import { getBrazilianPaginatorIntl } from './brazilian-paginator-intl';
import { AuthGuardService } from './guards/auth.guard';
import { GuestGuardService } from './guards/guest.guard';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    PipesModule,
    NgxMaskModule.forRoot(),
    NgxSummernoteModule.forRoot(),
  ],
  providers: [
    AuthGuardService,
    GuestGuardService,
    { provide: MatPaginatorIntl, useValue: getBrazilianPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  exports: [FormsModule, NgxMaskModule, NgxSummernoteModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
